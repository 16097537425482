/* Css */
nav {
  height: 80px;
  width: 100%;
  padding: 0 10px;
  color: var(--primaryColor);
  position: sticky;
  top: 0;
  transition: background-color 0.3s;
  background-color: var(--primaryBackground);
  border-bottom: 1px solid;
  display: grid;
  grid-template-columns: 1fr 5fr;
  grid-template-rows: auto;
  align-items: center;
  justify-content: center;
  z-index: 99;
}

.navOptions {
  transition: 0.5s left ease-in-out;
  display: grid;
  grid-template-columns: 5fr 1fr;
}

.navLinks {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navLinks a {
  color: var(--primaryColor);
  text-decoration: none;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  font-size: 1.125rem;
  padding: 5px 10px;
}

.navLinks a svg {
  width: 24px;
  height: 24px;
  fill: var(--primaryColor);
}

/* Botões de navegação */
.navButtons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.navButton {
  padding: 5px 10px;
  border: none;
  background-color: transparent;
  color: var(--primaryColor);
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

@media (max-width: 1100px) {
  .navButton {
    font-size: 1rem;
  }
}

/* Idiomas */
.navLanguage {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.navLanguage button {
  padding: 5px 10px;
  border: 1px solid var(--primaryColor);
  background-color: transparent;
  color: var(--primaryColor);
  cursor: pointer;
  transition: all 0.5s;
}

.navLanguage button:hover {
  background-color: var(--primaryColor);
  color: white;
}

.activeLinkLanguage {
  background-color: var(--primaryColor) !important;
  color: black !important;
}

@media (max-width: 1200px) {
  .navLanguage {
    flex-direction: column;
  }
}

/* Sticky navbar */

.navScroll {
  border: none;
  background-color: var(--stickyNavBar);
  transition: background-color 0.3s;
}

.navScroll .navButton:hover {
  color: black !important;
}

.navScroll .navLinks a,
.navScroll .navLinks a svg,
.navScroll .navButton {
  color: var(--stickyNavBarFont);
  fill: var(--stickyNavBarFont);
}

.navMenuButton {
  display: none;
  padding: 10px 30px;
  background: transparent;
  border: none;
  outline: none;
}
.navMenuButton svg {
  color: var(--primaryColor);
  fill: var(--primaryColor);
  cursor: pointer;
  width: 40px;
  height: 40px;
}

@media (max-width: 1000px) {
  .navOptions {
    opacity: 0;
    position: fixed;
    left: -400%;
  }
  .navMenuButton {
    padding: 0;
    display: block;
  }
  nav {
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
  }
  .FlyerMenu {
    transition: 0.3s left ease-in-out;
    justify-content: space-between;
    opacity: 1;
    width: 70%;
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    height: 100vh;
    background-color: var(--stickyNavBarDark);
    padding: 20px;
  }
  .FlyerMenu .navButtons {
    margin-top: 50px;
    align-items: start;
    gap: 40px;
    flex-direction: column;
  }
}
