.history {
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: var(--primaryBackground);
  color: var(--primaryColor);
  height: 600px;
}

.historyContainer {
  padding: 20px;
  border-radius: 5px;
  width: 60%;
  height: 400px;
}

@media (max-width: 1200px) {
  .historyContainer {
    width: 80%;
  }
}
@media (max-width: 900px) {
  .historyContainer {
    margin-top: 80px;
    height: 700px;
    width: 100%;
  }
  .careerInfoWrapper {
    flex-direction: column;
  }
  .history{
    align-items: start;
    padding: 0;
    height: 750px;
  }
}

@media (max-width: 550px) {
  .careerItemResume div {
    flex-direction: column;
    gap: 10px;
  }
  .historyContainer {
    height: 700px;
  }
  .history{
    height: 900px;
  }
}

.historyContainer h4 {
  margin-bottom: 20px;
  font-size: 3rem;
}

.careerInfoWrapper {
  gap: 20px;
  display: flex;
  height: auto;
}

.careerOptions {
  background-color: var(--secondaryBackground);
  color: var(--secondaryColor);
  height: 100%;
  min-width: 200px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.careerOption {
  width: 100%;
  text-align: center;
  padding: 20px;
  cursor: pointer;
}

.careerOptionSelected {
  background-color: var(--primarySubColor);
  color: var(--primaryColor);
  cursor: default;
  transition: all 0.5s ease-in-out;
}

.careerOption h5 {
  font-size: 1.5rem;
}

.careerOptionSelected h5::before {
  content: '➤ ';
}

.careerItemResume {
  gap: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.careerItemResume div {
  gap: 20px;
  display: flex;
  justify-content: space-between;
}

.careerItemResume div h5 {
  font-size: 2rem;
}

.careerItemResume div p {
  font-weight: 600;
  font-size: 1.25rem;
}

.careerItemResume p {
  font-size: 1.125rem;
}
