.homeSection {
  width: 100%;
  height: calc(100vh);
  background-color: var(--primaryBackground);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}

.homeInfo {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

@media (max-width: 800px) {
  .homeInfo {
    flex-direction: column;
    margin-top: 20px;
  }
  .aboutMeText p {
    text-align: center;
  }
  .homeSection {
    justify-content: start;
  }
}

.aboutMePhoto {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.aboutMePhoto img {
  z-index: 5;
  position: relative;
  box-shadow: 16px 14px 20px #0000008c;
  width: 90%;
  border-radius: 20px;
}

.aboutMeText {
  flex-direction: column;
  width: 30%;
  height: 100%;
  color: var(--primaryColor);
  display: flex;
}

@media (max-width: 800px) {
  .aboutMeText {
    width: 55% !important;
  }
  .aboutMeName {
    display: flex;
  }
  .aboutMeText p {
    text-align: start;
  }
  .aboutMePhoto {
    width: 55% !important;
  }
  .photoBorder {
    width: 97% !important;
    top: 18px !important;
    height: 97% !important;
  }
  .photoBorderGradient {
    width: 97% !important;
    top: 18px !important;
    height: 97% !important;
  }
  .aboutMePhoto img {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .aboutMeText {
    width: 70% !important;
  }

  .aboutMePhoto {
    width: 70% !important;
  }
}

@media (max-width: 500px) {
  .aboutMeText {
    width: 80% !important;
  }

  .aboutMePhoto {
    width: 80% !important;
  }
  .imgLinks {
    top: 110% !important;
  }
}

.aboutMeName {
  align-items: end;
  display: flex;
  width: 70%;
}

.aboutMeText h1 {
  width: auto;
  z-index: 1;
  padding: 0;
  font-size: 5rem;
}

.aboutMeText p {
  margin-bottom: 10px;
  font-size: 1.125rem;
}

@media (min-width: 800px) {
  .aboutMeText p {
    font-size: 1.5rem;
  }
}

@media (max-width: 1200px) {
  .aboutMeText h1 {
    font-size: 3.5rem;
  }
  .waveHand {
    font-size: 1rem !important;
  }
}

/* Gradiente */

.photoBorder {
  width: 90%;
  height: 98%;
  position: absolute;
  left: 27px;
  top: 10px;
  border-radius: 20px;
  background: var(--primaryBackground);
  z-index: 1;
}

.photoBorderGradient {
  width: 90%;
  height: 98%;
  position: absolute;
  left: 27px;
  top: 10px;
  z-index: 0;
  border-radius: 20px;
}

.photoBorderGradient {
  --borderWidth: 3px;
  background: #1d1f20;
  border-radius: var(--borderWidth);
}

.photoBorderGradient:after {
  content: '';
  position: absolute;
  top: calc(-1 * var(--borderWidth));
  left: calc(-1 * var(--borderWidth));
  height: calc(100% + var(--borderWidth) * 2);
  width: calc(100% + var(--borderWidth) * 2);
  background: linear-gradient(
    60deg,
    #f79533,
    #f37055,
    #ef4e7b,
    #a166ab,
    #5073b8,
    #1098ad,
    #07b39b,
    #6fba82
  );
  border-radius: 20px;
  z-index: -1;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}

.imgLinks {
  display: flex;
  position: absolute;
  top: -30px;
  gap: 50px;
  width: 100%;
  justify-content: center;
}

.imgLinks svg {
  width: 24px;
  height: 24px;
  fill: var(--primaryColor);
}

@media (max-width: 800px) {
  .imgLinks {
    gap: 30px;
    width: auto;
    height: auto;
    top: 105%;
  }
}
