.Modal {
  position: fixed;
  z-index: 9999;
  width: 100%;
  top: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding: 40px 80px;
  overflow-x: hidden;
}

@media (max-width: 600px) {
  .Modal {
    padding: 30px;
  }
}

.modalSection {
  overflow-y: auto;
  background-color: var(--primaryBackground);
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  height: 100%;
}

.modalSection::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #000000;
}

.modalSection::-webkit-scrollbar {
  width: 8px;
  background-color: #000000;
}

.modalSection::-webkit-scrollbar-thumb {
  background-color: #f5f5f5;
}

@media (max-width: 600px) {
  .Modal {
    padding: 0 !important;
  }
}

.modalButtons {
  position: sticky;
  width: 100%;
  top: -20px;
  z-index: 9999;
  padding: 10px;
  background-color: var(--primaryBackground);
  box-shadow: 16px 14px 20px #0000008c;
  margin: 20px 0;
}
