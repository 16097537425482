.tecnologies {
  width: 100%;
  height: 120vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 20px;
  font-size: 1.5rem;
  padding: 20px;
  background-color: var(--secondaryBackground);
}

.animateTag {
  margin-top: 100px;
  width: 80%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.animateTag img {
  z-index: 3;
  border-radius: 30px;
  width: 400px;
}

.tecTitle {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 0 20px;
  font-size: 6.5rem;
  color: white;
  border-radius: 20px;
  white-space: nowrap;
}

@media (max-width: 800px) {
  .tecTitle {
    font-size: 5rem;
  }
  .animateTag img {
    width: 300px;
  }
}

@media (max-width: 500px) {
  .tecTitle {
    font-size: 3rem;
  }
  .animateTag img {
    width: 200px;
  }
}

.firstTecTitle {
  z-index: 4;
  -webkit-text-stroke: 2px white;
  color: transparent;
}

.tecResume {
  width: 60%;
  text-align: center;
  color: var(--secondaryColor);
}

@media (max-width: 600px) {
  .tecResume {
    width: 90%;
    font-size: 1.5rem;
  }
}

@media (max-width: 400px) {
  .tecResume {
    width: 100%;
    font-size: 1.125rem;
  }
}

.techsDiv {
  margin-top: 50px;
  display: flex;
  width: 80%;
  overflow-x: auto;
  gap: 50px;
  user-select: none;
  touch-action: pan-y;
  scrollbar-width: none;
}
.techsDiv::-webkit-scrollbar {
  display: none; /* Esconde a barra de rolagem no Chrome, Edge, etc. */
}

/* techCard */

.techCard {
  transition: all 0.5s ease-in-out;
  position: relative;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  padding: 10px 20px;
  border-radius: 10px;
  min-width: 250px;
  height: 180px;
  border: 1px solid var(--secondaryColor);
  background-color: #040f0f;
  color: var(--secondaryColor);
  cursor: pointer;
}

.techsDivWrapper {
  cursor: grab;
  display: flex;
  gap: 50px;
}

@media (max-width: 500px) {
  .techsDivWrapper {
    gap: 20px;
  }
}

.techTitle {
  font-size: 2rem;
  font-weight: 600;
  text-transform: uppercase;
}

.techIcon {
  font-size: 5rem;
}

@media (max-width: 600px) {
  .techTitle {
    font-size: 1.5rem;
  }
  .techIcon {
    font-size: 4rem;
  }
}

@media (max-width: 450px) {
  .techCard {
    min-width: 200px;
  }
}
