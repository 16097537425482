.projectsSection {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-color: var(--secondaryBackground);
  color: var(--textColor);
  text-align: center;
  color: white;
  padding: 20px;
}

.filterContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 20px;
}

.filterContainer input {
  background-color: var(--primaryBackground);
  width: 50%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  color: var(--textColor);
  font-size: 1rem;
  outline: none;
}

.filterContainer input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.filterContainer select {
  background-color: var(--primaryBackground);
  padding: 10px;
  border: none;
  border-radius: 5px;
  color: var(--textColor);
  font-size: 1rem;
}

.slider {
  max-width: 100%;
  margin: 25px;
  overflow-x: hidden;
  transition: 0.4s ease-out transform;
}

.slider:active {
  transform: scale(0.95);
}

.slides {
  display: flex;
  list-style: none;
  will-change: transform;
}

.slides:hover {
  cursor: grab;
}

.slides:active {
  cursor: grabbing;
}

.slides li:not(:last-of-type) {
  margin-right: 15px;
}

.project {
  height: 700px;
  width: 850px;
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  user-select: none;
  background-color: var(--primaryBackground);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
}

.project p {
  font-size: 1.2rem;
  margin-bottom: 20px;
  height: 10%;
}

.project img {
  width: 100%;
  height: auto; /* Mantém a proporção da imagem */
  max-height: 400px; /* Define a altura máxima das imagens */
  object-fit: cover; /* Redimensiona a imagem para preencher a área definida */
  border-radius: 8px; /* Adiciona cantos arredondados */
  pointer-events: none;
}

/*! Titulo */

.projectHeader {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  position: relative;
}

.project h3 {
  font-size: 2rem;
  margin: 0;
  flex-grow: 1;
  text-align: center;
}

.project div a {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  text-decoration: none;
  color: var(--textColor);
  border: 2px solid var(--textColor);
  border-radius: 5px;
  color: var(--lightblue);
  -webkit-transition: all 200ms cubic-bezier(0.39, 0.5, 0.15, 1.36);
  -moz-transition: all 200ms cubic-bezier(0.39, 0.5, 0.15, 1.36);
  -ms-transition: all 200ms cubic-bezier(0.39, 0.5, 0.15, 1.36);
  -o-transition: all 200ms cubic-bezier(0.39, 0.5, 0.15, 1.36);
  transition: all 200ms cubic-bezier(0.39, 0.5, 0.15, 1.36);
  text-decoration: none;
  border-radius: 4px 2px 4px 2px;
  padding: 20px 30px;
  box-shadow: 0 0px 0px 2px inset;
}

.project div a.leftLink {
  top: 0;
  left: 0;
}

.project div a.leftLink:hover {
  box-shadow: #2dba4e 0 0px 0px 40px inset;
  color: black;
}

.project div a.rightLink {
  top: 0;
  right: 0;
}

.project div a.rightLink:hover {
  box-shadow: #2b3137 0 0px 0px 40px inset;
  color: white;
}

.project p {
  margin: 20px 0;
}

/* Techs */

.technologies {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: auto; /* Pushes the technologies to the bottom */
  overflow-x: auto;
}

.technologies span {
  margin: 0 10px;
  border-radius: 5px;
}

@media (max-width: 900px) {
  .project {
    height: 700px;
    max-width: 650px;
  }
}

@media (max-width: 500px) {
  .filterContainer {
    flex-direction: column;
  }
  .links {
    position: relative !important;
  }
  .filterContainer input {
    width: 90%;
  }
  .filterContainer select {
    width: 90%;
  }
  .project {
    height: 500px;
    width: 400px;
  }
  .project div a {
    padding: 10px 20px;
    position: initial;
  }
  .projectHeader {
    flex-direction: column;
    margin-bottom: 10px;
  }
  .projectHeader div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    margin-top: 10px;
  }
  .project p {
    width: 100%;
    height: 30%;
    margin: 0;
  }
}

@media (max-width: 450px) {
  .project img {
    display: none;
  }
  .project {
    height: 400px;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 400px) {
  .project {
    width: 250px;
  }
  .project p {
    font-size: 1rem;
  }
  .project div a {
    padding: 10px 10px;
  }
}

.links {
  top: 5px;
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
