.certificatesSection {
  background-color: var(--primaryBackground);
  color: var(--primaryColor);
  width: 100%;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.certificate {
  width: 250px;
  height: 200px;
  padding: 10px;
  text-align: center;
  background-color: var(--secondaryBackground);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: var(--secondaryColor);
}

.certificate h3 {
  font-size: 1rem;
  width: 100%;
  height: 25%;
}

.certificate p {
  font-size: 0.875rem;
  width: 100%;
  height: 50%;
}

.certificate span {
  border: 1px solid var(--secondaryColor);
  padding: 5px 15px;
}

.certificate a {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: var(--textColor);
  border: 1px solid var(--textColor);
  border-radius: 12px;
  -webkit-transition: all 200ms cubic-bezier(0.39, 0.5, 0.15, 1.36);
  -moz-transition: all 200ms cubic-bezier(0.39, 0.5, 0.15, 1.36);
  -ms-transition: all 200ms cubic-bezier(0.39, 0.5, 0.15, 1.36);
  -o-transition: all 200ms cubic-bezier(0.39, 0.5, 0.15, 1.36);
  transition: all 200ms cubic-bezier(0.39, 0.5, 0.15, 1.36);
  text-decoration: none;
  border-radius: 4px;
  padding: 5px 25px;
  box-shadow: 0 0px 0px 2px inset;
}
.certificate a:hover {
  box-shadow: var(--secondaryColor) 0 0px 0px 40px inset;
  color: black;
}

@media (max-width: 820px) {
  .certificatesSection {
    gap: 20px;
  }
  .certificate {
    width: 200px;
    height: 150px;
  }
  .certificate h3 {
    font-size: 0.875rem;
    height: auto;
  }
  .certificate p {
    display: none;
  }
  .certificate span {
    font-size: 0.75rem;
  }
}

@media (max-width: 500px) {
  .certificate {
    width: 130px;
    height: 150px;
  }
  .certificate h3 {
    font-size: 0.85rem;
  }
  .certificate a {
    padding: 5px 15px;
  }
}
