.getinTouch {
  height: 100vh;
  width: 100%;
  background-color: var(--secondaryBackground);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.messageContainer {
  background-color: rgba(0, 0, 0, 0.4);
  width: 55%;
  height: 75%;
  border-radius: 16px;
  display: grid;
  grid-template-columns: 2fr 5fr;
  align-items: center;
}

.successSendContainer {
  grid-template-columns: 1fr;
}

.successSendContainer h3 {
  margin: 0 !important;
}
.messageContainer h3 {
  text-align: center;
  color: var(--primaryColor);
  height: 100%;
  margin-left: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
}

.inputBox {
  position: relative;
  width: 400px;
}

.inputBox input,
.inputBox textarea {
  width: 100%;
  padding: 10px 30px 10px 10px;
  border: 1px solid;
  background: none;
  outline: none;
  color: var(--lightblue);
  border-radius: 5px;
  resize: none;
  font-size: 1.25rem;
}

.inputBox textarea {
  height: 200px;
}

.inputBox span {
  position: absolute;
  left: 0;
  padding: 10px;
  text-transform: uppercase;
  pointer-events: none;
  transition: 0.5s;
  color: var(--lightblue);
}

.inputBox input:valid ~ span,
.inputBox input:focus ~ span,
.inputBox textarea:valid ~ span,
.inputBox textarea:focus ~ span {
  transform: translateX(10px) translateY(-18px);
  font-size: 0.65rem;
  background-color: rgb(11, 10, 10);
  border-left: 1px solid;
  border-right: 1px solid;
}

form {
  transition: 0.3s ease;
  gap: 20px;
  display: flex;
  flex-direction: column;

  align-items: center;
}

.cta {
  border: none;
  background: none;
  position: relative;
  cursor: pointer;
  margin: auto;
  color: var(--lightblue);
  padding: 19px 22px;
  transition: all 0.2s ease;
}

.cta[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

.cta:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  border-radius: 28px;
  background: rgba(228, 222, 208, 1);
  width: 56px;
  height: 56px;
  transition: all 0.3s ease;
}
.cta span {
  position: relative;
  font-size: 16px;
  line-height: 18px;
  font-weight: 900;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  vertical-align: middle;
}
.cta svg {
  position: relative;
  top: 0;
  margin-left: 10px;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: var(--lightblue);
  stroke-width: 2;
  transform: translateX(-5px);
  transition: all 0.3s ease;
}
.cta:hover:before {
  width: 100%;
  background: #e4ded0;
}
.cta:hover svg {
  stroke: black;
  transform: translateX(0);
}
.cta:active {
  transform: scale(0.96);
}
.cta:hover span {
  color: black;
}
.sendAgain {
  padding: 20px 0;
  max-width: 400px;
  min-width: 250px;

  position: absolute;
  top: 70%;
  border: 1px solid var(--lightblue);
  background: none;
  color: var(--lightblue);
  font-size: 1.25rem;
  overflow: hidden;
  cursor: pointer;
}

.sendAgain::before {
  content: '';
  width: 100%;
  height: 50%;
  top: -51%;
  left: 0;
  position: absolute;
  z-index: 0;
  z-index: -5;
  transition: 0.3s all ease-in-out;
}

.sendAgain::after {
  z-index: -5;
  background-color: var(--lightblue);
  bottom: -51%;
  position: absolute;
  content: '';
  width: 100%;
  height: 0;
  left: 0;
  transition: 0.3s all ease-in-out;
}

.sendAgain:hover {
  color: black;
  font-weight: bold;
  transition: 0.3s all ease-in-out;
  z-index: 50;
}

.sendAgain:hover::before {
  height: 50%;
  transition: 0.3s all ease-in-out;
  background-color: var(--lightblue);
  top: 0;
}

.sendAgain:hover::after {
  height: 50%;
  transition: 0.3s all ease-in-out;
  bottom: 0;
}

@media (max-width: 1200px) {
  .inputBox {
    width: 300px !important;
  }
  .messageContainer {
    height: 90%;
  }
}
@media (max-width: 1000px) {
  .messageContainer {
    gap: 50px;
    flex-direction: column;
  }
}

@media (max-width: 600px) {
  .inputBox {
    width: 200px !important;
  }
  .messageContainer {
    display: flex;
    height: 90%;
  }
  .messageContainer h3 {
    margin: 0;
    width: 100%;
    height: 15%;
    font-size: 2rem;
    width: 90%;
  }
  .messageContainer form {
    width: 100%;
  }
  .inputBox {
    width: 90% !important;
  }
}

@media (max-width: 500px) {
  .messageContainer h3 {
    font-size: 1.5rem;
    width: 90%;
  }
  .messageContainer {
    gap: 5px;
    height: 85%;
    max-width: 80%;
  }
}

@media (max-width: 900px) {
  .messageContainer {
    width: 90%;
  }
}
